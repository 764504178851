<template>
  <a-row :gutter="[16, 8]" class="py-4 px-2 p-md-4 mb-4">
    <a-col :xs="24" :sm="24" :md="8" :lg="8">
      <a-select
        id="model-version-select-task"
        ref="admin_model_version_org_select"
        v-model:value="orgName"
        show-search
        placeholder="Select Organization"
        class="w-100"
        :options="orgOptions"
        :filter-option="true"
        option-filter-prop="label"
        :loading="isLoadingOrganizations"
        @change="$refs.admin_model_version_org_select.blur()"
      />
    </a-col>
    <a-col :xs="24" :sm="24" :md="8" :lg="8">
      <a-select
        id="model-version-select-task"
        ref="model_version_task_select"
        v-model:value="task_id"
        show-search
        placeholder="Select Task"
        class="w-100"
        :options="taskOptions"
        :disabled="!orgName"
        :filter-option="true"
        option-filter-prop="label"
        :loading="isFetchingTasks"
        @change="$refs.model_version_task_select.blur()"
      />
    </a-col>
    <a-col span="8">
      <a-button
        type="primary"
        :disabled="!orgName || !task_id"
        @click="onClickSubmit"
      >
        Submit
      </a-button>
    </a-col>
    <a-col span="24" class="mt-4">
      <model-versions-table
        :org-name="orgName"
        :task-id="task_id"
        :task-name="taskName"
      />
    </a-col>
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TaskService from 'src/services/tasks.js';
import ModelVersionsTable from './ModelVersionsTable.vue';

export default {
  components: {
    ModelVersionsTable,
  },
  inject: ['toast'],
  data() {
    return {
      isFetchingTasks: false,
      orgTaskList: [],
      orgName: null,
      task_id: null,
      taskName: '',
    };
  },

  computed: {
    ...mapGetters(['selectedTask', 'organizations', 'isLoadingOrganizations']),
    taskOptions() {
      return this.orgTaskList?.map((task) => ({
        value: task.id,
        label: task.taskName,
      }));
    },
    orgOptions() {
      return this.organizations?.map((org) => ({
        value: org.Org_name,
        label: org.Org_name,
      }));
    },
  },

  watch: {
    task_id(value) {
      if (!value) return;
      this.taskName = this.orgTaskList.find((t) => t.id === value)?.taskName;
      this.fetchTaskDetails(value);
    },
    orgName(value) {
      let query = { organizations: value };
      this.getOrganizationTasks(query);
    },
    orgTaskList(list) {
      if (!this.task_id) return;
      this.taskName = list.find((t) => t.id === this.task_id)?.taskName;
    },
  },

  created() {
    this.getOrganizations();
    this.fetchModelTypes();
  },

  mounted() {
    this.populateParams();
  },

  unmounted() {
    this.clearTaskDetails();
  },

  methods: {
    ...mapActions([
      'getOrganizations',
      'fetchTaskDetails',
      'fetchModelVersionsList',
      'clearTaskDetails',
      'fetchModelTypes',
    ]),

    setRouteQueryParams(query) {
      this.$router.replace({
        name: this.$route.name,
        query,
      });
    },

    populateParams() {
      const { taskId, organization } = this.$route.query;
      this.task_id = taskId && Number(taskId);
      this.orgName = organization;
      if (taskId) {
        this.fetchModelVersionsList(taskId);
      }
    },

    async getOrganizationTasks(queryParams) {
      this.isFetchingTasks = true;
      const [error, data] = await TaskService.fetchAllTaskAdminPanel(
        queryParams,
        false
      );
      this.isFetchingTasks = false;
      if (error) {
        this.toast.error('Error in fetching Tasks of this organization!');
        return;
      }
      this.orgTaskList = data;
    },

    onClickSubmit() {
      this.fetchModelVersionsList(this.task_id);
      this.setRouteQueryParams({
        organization: this.orgName,
        taskId: this.task_id,
      });
    },
  },
};
</script>

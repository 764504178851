export const modelTypesValidFiles = {
  'Model A': ['model_a.pth.tar', 'subStepTimes.json', 'label_split.json'],
  'Model C': ['model.pth.tar', 'subStepTimes.json', 'label_split.json'],
  'Model D': ['detector.pt', 'detector.json', 'Detector.py', 'logic.py'],
  'Model AL': [
    'model_a.pth.tar',
    'model_al.pth.tar',
    'subStepTimes.json',
    'label_split.json',
  ],
  'Model CL': [
    'model.pth.tar',
    'model_cl.pth.tar',
    'subStepTimes.json',
    'label_split.json',
  ],
  'Model O': ['detector.pt', 'detector.json', 'Detector.py'],
  'Model AD': [
    'model_a.pth.tar',
    'subStepTimes.json',
    'detector.pt',
    'detector.json',
    'Detector.py',
    'logic.py',
  ],
  'Model A4LT': ['model-a4lt.pth.tar', 'subSteptimes.json', 'label_split.json'],
  'Model A4LT-L': [
    'model-a4lt.pth.tar',
    'model-a4lt-l.pth.tar',
    'subSteptimes.json',
    'label_split.json',
  ],
};

export const validFiles = ['.pt', '.pth.tar', '.json', '.py'];

export const adminModelVersionTable = [
  {
    title: 'Model Version',
    dataIndex: 'version_number',
    key: 'version',
    align: 'center',
    width: '10%',
  },
  {
    title: 'Created At',
    dataIndex: 'date',
    key: 'created_at',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Model Type',
    dataIndex: 'model_type',
    key: 'created_at',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Qc check',
    dataIndex: 'qc_check',
    key: 'qc_check',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: '30%',
  },
  {
    title: 'Deleted',
    dataIndex: 'deleted',
    align: 'center',
    width: '10%',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    align: 'center',
    width: '20%',
  },
];

export const userModelVersionTable = [
  {
    title: 'Time Trained',
    dataIndex: 'date',
    key: 'date',
    width: '15%',
    align: 'center',
  },
  {
    title: 'Version',
    dataIndex: 'version_number',
    key: 'version',
    width: '10%',
    align: 'center',
  },
  {
    title: 'Version Label',
    dataIndex: 'description',
    key: 'description',
    width: '35%',
    align: 'center',
  },
  {
    title: 'Set Default',
    dataIndex: 'id',
    width: '10%',
    align: 'center',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    width: '30%',
    align: 'center',
  },
];

export const userModelVersionModalTable = [
  {
    title: 'Model Version',
    dataIndex: 'date',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Model Type',
    dataIndex: 'model_type',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    align: 'center',
    width: '50%',
  },
  {
    title: 'Select',
    dataIndex: 'id',
    width: '15%',
    align: 'center',
  },
];
